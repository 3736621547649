import { FC, useEffect } from 'react';
import {
  Control,
  FormState,
  UseFormClearErrors,
  UseFormRegister,
  UseFormWatch
} from 'react-hook-form';
import { CardFormShape } from '../CardForm';
import { TextInput } from '../../../common/TextInput';

type CardInitialUserProps = {
  register: UseFormRegister<CardFormShape>;
  updateValue: (
    fieldName: keyof CardFormShape,
    value: string | boolean | number,
    shouldDirty?: boolean,
  ) => void;
  formState: FormState<CardFormShape>;
  clearErrors: UseFormClearErrors<CardFormShape>
  watch: UseFormWatch<CardFormShape>;
  control: Control<CardFormShape>;
};

export const CardInitialUser: FC<CardInitialUserProps> = ({
  register,
  updateValue,
  formState,
  watch,
  control,
}) => {

  const isInitialUserShow = Boolean(watch('initialUserShow'));
  const initialUserResponseLimitType = watch('initialUserResponseLimitType');

  useEffect(() => {
    if (!initialUserResponseLimitType) {
      updateValue("initialUserResponseLimitType", "word")
    }
  }, []);

  return (
    <div className="grid grid-cols-2 gap-x-5">
      <div className="">
        <label htmlFor="isInitialUserShow" className="inline-block text-sm text-gray-400 mb-1">
          Add To Suit Name Bubble?
        </label>
        <div className="flex mb-3">
          <div
            onClick={() => updateValue('initialUserShow', true, true)}
            className="h-11 border border-gray-300 px-2 py-1 rounded w-full mr-2 flex items-center justify-between"
          >
            <p>Yes</p>
            <input
              type="checkbox"
              name="isInitialUserShow"
              onChange={() => {
              }}
              checked={isInitialUserShow}
            />
          </div>
          <div
            onClick={() => updateValue('initialUserShow', false, true)}
            className="h-11 border border-gray-300 px-2 py-1 rounded w-full flex items-center justify-between"
          >
            <p>No</p>
            <input
              type="checkbox"
              name="isNotInitialUserShow"
              onChange={() => {
              }}
              checked={!isInitialUserShow}
            />
          </div>
        </div>
        {formState.errors?.initialUserShow && (
          <p className="text-red-500 text-xs mt-1">
            {formState.errors.initialUserShow.message}
          </p>
        )}
      </div>
      <div>
        {isInitialUserShow && (
          <TextInput
            label="Preposition"
            name={'initialUserPropositional'}
            labelStyle="block text-sm text-gray-400 mb-2"
            inputStyle="w-full rounded mt-2 py-2 px-4 block border-gray-200 border-2"
            errorStyle="text-sm text-red-400"
            controlStyle="bg-blue-30 w-full relative"
            control={control}
            placeholder=""
          />
        )}
      </div>
      <div className="w-full relative mb-3">
        <label
          className="text-sm text-gray-400"
          htmlFor="initialUserResponseLimitNum"
        >
          Initial Response Limit
        </label>
        <div className="w-full flex justify-between space-x-2">
          <input
            id="initialUserResponseLimitNum"
            type="number"
            min={0}
            placeholder="100"
            {...register('initialUserResponseLimitNum', { required: "Field is required" })}
            className="w-1/2 rounded p-2 selected:bg-white border-gray-200 border-2 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none disabled:text-gray-400 disabled:bg-gray-100"
          />
          <select
            id="initialUserResponseLimitType"
            {...register('initialUserResponseLimitType')}
            className="w-1/2 rounded px-2 py-2.5 bg-white border-gray-200 border-2 disabled:text-gray-400 disabled:bg-gray-100"
          >
            <option value="word">Words</option>
            <option value="character">Characters</option>
          </select>
        </div>
        {formState?.errors.initialUserResponseLimitNum?.message && (
          <p className="text-red-500 italic text-xs absolute">
            {formState?.errors.initialUserResponseLimitNum.message}
          </p>
        )}
        {formState?.errors.initialUserResponseLimitType?.message && (
          <p className="text-red-500 italic text-xs absolute">
            {formState?.errors.initialUserResponseLimitType.message}
          </p>
        )}
      </div>
      <div className="col-span-2">
        <TextInput
          label="Initial Prompt Title"
          name={'initialUserPromptTitle'}
          labelStyle="block text-sm text-gray-400 mb-2"
          inputStyle="w-full rounded mt-2 py-2 px-4 block border-gray-200 border-2"
          errorStyle="text-sm text-red-400"
          controlStyle="bg-blue-30 w-full mb-4 relative"
          control={control}
          rules={{ required: "Field is required" }}
        />
      </div>
      <div className="col-span-2">
        <TextInput
          label="Initial Subtext"
          name={'initialUserSubText'}
          labelStyle="block text-sm text-gray-400 mb-2"
          inputStyle="w-full rounded mt-2 py-2 px-4 block border-gray-200 border-2"
          errorStyle="text-sm text-red-400"
          controlStyle="bg-blue-30 w-full mb-4 relative"
          control={control}
          rules={{ required: "Field is required" }}
        />
      </div>
    </div>
  );
};
