import classNames from 'classnames';
import { FC, useEffect, useState } from 'react';
import { UseFormSetError, useForm } from 'react-hook-form';
import { HiArrowLeft, HiOutlineTrash } from 'react-icons/hi';
import { showToast } from 'lib/toast';
import {
  CardFile,
  PostCardRequestData,
  cardsService,
} from 'lib/services/cardsServices';
import { formatDateWithTime } from 'lib/utils/formatDate';
import { CardForm } from 'components/pages/TabManagementPage/CardForm';
import { CardFilesView } from 'components/pages/TabManagementPage/CardFilesView';
import { CardPreview } from './CardPreview';
import { CardMediaTypes, CardTypes, Direction } from 'lib/types/entities';
import { Modal } from '../Modal';
import { ModalMode } from 'components/layouts/ModalLayout';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { CardQrCode } from './CardQrCode';
import { AxiosError } from 'axios';
import { formatFiles } from 'lib/utils/formatFiles';

export type CardInfoModalProps = {
  readonly onApprove: (
    card: PostCardRequestData & {
      files: Array<CardFile>;
    },
    setError: UseFormSetError<PostCardRequestData>,
    ids: string[],
  ) => void;
  readonly isDraft: boolean;
  readonly cardData: {
    id: string;
    isCreatingFlow?: boolean | undefined;
  };
  readonly closeModal: () => void;
  readonly onDelete: (id: string) => void;
  readonly onUpdate: (
    card: PostCardRequestData & { files: Array<CardFile> },
    setError: UseFormSetError<PostCardRequestData>,
    isClosing: boolean,
  ) => void;
};

const cardInfoTabs: { [x: string]: string } = {
  INFO: 'Info',
  MEDIA: 'Media',
  CARD_VIEW: 'Card view',
  CARD_QR_CODE: 'QR Code',
};

const { getCardById, patchCardFilesSorting, postCardsAsDraft } = cardsService();

export const CardInfoModal: FC<CardInfoModalProps> = ({
  cardData,
  closeModal,
  onUpdate,
  isDraft,
  onApprove,
  onDelete,
}) => {
  const [isCardLoading, setIsCardLoading] = useState(true);
  const [isApproveModalVisible, setIsApproveModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [currentTab, setCurrentTab] = useState(cardInfoTabs.INFO);
  const [card, setCard] = useState<
    PostCardRequestData & {
      '@id': string;
      files: Array<CardFile>;
      sequenceNumber: number;
      createdAt: string;
      updatedAt: string;
      used: boolean;
    }
  >({
    '@id': '',
    title: '',
    type: CardTypes.affirmation,
    promptTitle: '',
    promptTitleShow: true,
    mediaType: 'text',
    responseType: 'text',
    frequency: 1,
    active: false,
    sequenceNumber: 0,
    advanced: false,
    similiatityGroup: 1,
    responseLimitType: 'word',
    responseLimitNum: 100,
    subtextRegular: '',
    promptFinishTab: '',
    subtextStarter: '',
    used: false,
    createdAt: new Date().toDateString(),
    updatedAt: new Date().toDateString(),
    files: [],
    initialUser: false,
  });

  const {
    register,
    setValue,
    formState,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    control,
  } = useForm<PostCardRequestData>({
    defaultValues: {
      ...card,
    },
    mode: 'onChange',
  });
  const cardType = watch('type');
  const isMediaTypeCraft = watch('mediaType') === CardMediaTypes.craft;
  const isMediaTypeAudioText = watch('mediaType') === CardMediaTypes.audio_text;
  const cardPromptTitle = watch('promptTitle');

  const getFullCardInfo = async () => {
    setIsCardLoading(true);
    try {
      const cardInfoResponse = await getCardById(
        cardData.id.replace('/cards/', ''),
      );
      const cardInfoData = cardInfoResponse.data;
      setCard({
        ...card,
        ...cardInfoData,
        files: cardInfoData.files.sort((prevFile: CardFile, file: CardFile) =>
          prevFile && file ? prevFile?.sorting - file?.sorting : -1,
        ),
      });
      Object.keys(cardInfoData).forEach((field: string) => {
        if (field === 'files') return;
        setValue(field as keyof PostCardRequestData, cardInfoData[field]);
        return;
      });
    } catch (error) {
      showToast({
        type: 'error',
        title: 'Error',
        message: 'Cannot access card info',
      });
    }
    setIsCardLoading(false);
  };
  const onSubmit = (
    updatedCard: PostCardRequestData & { files?: Array<CardFile> },
    isClosing: boolean,
  ) => {
    if (
      !card.files.length &&
      updatedCard.mediaType === CardMediaTypes.audio_text
    ) {
      showToast({
        type: 'warning',
        title: 'Please add audio files.',
        message:
          'Cards with prompt media - audio, should have at least 1 file. Please add files or change prompt media.',
      });
      return;
    }
    const { createdAt, files, active, ...patchCardFields } = updatedCard;
    const updatedFiles = card?.files || [];

    setCard({ ...card, ...patchCardFields, files: updatedFiles });

    onUpdate({
      ...patchCardFields,
      title: patchCardFields.title.trim(),
      active: Boolean(active),
      responseLimitNum: Number(patchCardFields.responseLimitNum),
      promptTitle: patchCardFields.promptTitle.trim(),
      promptTitleShow: Boolean(patchCardFields.promptTitleShow),
      subtextRegular: patchCardFields.subtextRegular.trim(),
      promptFinishTab: patchCardFields.promptFinishTab.trim(),
      subtextStarter: patchCardFields.subtextStarter.trim(),
      files: updatedFiles,
      ...(isMediaTypeCraft ? {
        initialUser: Boolean(updatedCard.initialUser),
        initialUserShow: Boolean(updatedCard.initialUserShow),
        initialUserPropositional: updatedCard.initialUserPropositional || "",
        initialUserPromptTitle: updatedCard.initialUserPromptTitle || null,
        initialUserSubText: updatedCard.initialUserSubText || null,
        initialUserResponseLimitType: updatedCard.initialUserResponseLimitType || null,
        initialUserResponseLimitNum: Number(updatedCard.initialUserResponseLimitNum) || null,
      } : {
        initialUser: false,
        initialUserShow: null,
        initialUserPropositional: null,
        initialUserPromptTitle: null,
        initialUserSubText: null,
        initialUserResponseLimitType: null,
        initialUserResponseLimitNum: null,
      }),
    }, setError, isClosing);
  };

  const onDraftSubmit = async (
    updatedCard: PostCardRequestData & { files?: CardFile[] },
  ) => {
    if (
      !card.files.length &&
      updatedCard.mediaType === CardMediaTypes.audio_text
    ) {
      showToast({
        type: 'warning',
        title: 'Please add audio files.',
        message:
          'Cards with prompt media - audio, should have at least 1 file. Please add files or change prompt media.',
      });
      return;
    }
    try {
      await postCardsAsDraft({
        title: updatedCard.title,
        active: updatedCard.active,
        responseType: updatedCard.responseType,
        type: updatedCard.type,
        frequency: Number(updatedCard.frequency),
        similiatityGroup: Number(updatedCard.similiatityGroup),
        ...(updatedCard.responseType === 'photo'
          ? {
              responseLimitType: null,
              responseLimitNum: null,
            }
          : {
              responseLimitType: updatedCard.responseLimitType,
              responseLimitNum: Number(updatedCard.responseLimitNum),
            }),
        subtextRegular: updatedCard.subtextRegular,
        subtextStarter: updatedCard.subtextStarter,
        mediaType: updatedCard.mediaType,
        advanced: updatedCard.advanced,
        promptTitle: updatedCard.promptTitle,
        promptTitleShow: updatedCard.promptTitleShow,
        promptFinishTab: updatedCard.promptFinishTab,
        craftSentence: updatedCard.craftSentence,
        craftExpressions: updatedCard.craftExpressions,
        files: updatedCard.mediaType === 'text' ? [] : formatFiles(card.files),
        reference: card['@id'],
        ...(isMediaTypeCraft ? {
          initialUser: Boolean(updatedCard.initialUser),
          initialUserShow: Boolean(updatedCard.initialUserShow),
          initialUserPropositional: updatedCard.initialUserPropositional || "",
          initialUserPromptTitle: updatedCard.initialUserPromptTitle || null,
          initialUserSubText: updatedCard.initialUserSubText || null,
          initialUserResponseLimitType: updatedCard.initialUserResponseLimitType || null,
          initialUserResponseLimitNum: Number(updatedCard.initialUserResponseLimitNum) || null,
        } : {
          initialUser: false,
          initialUserShow: null,
          initialUserPropositional: null,
          initialUserPromptTitle: null,
          initialUserSubText: null,
          initialUserResponseLimitType: null,
          initialUserResponseLimitNum: null,
        }),
      });
      showToast({
        type: 'success',
        title: 'Tab successfully saved as draft in Tab Draft Management!',
      });
    } catch (e) {
      const error = e as AxiosError;
      if (error.status === 409) {
        showToast({
          title: 'Card is already exist in Draft Management',
          type: 'warning',
        });
      } else {
        showToast({
          title: 'Something went wrong with copying to draft',
          type: 'error',
        });
      }
    }
  };

  const onUpdateFile = async (newFileData: CardFile[]) => {
    setCard((prevValue) => {
      return { ...prevValue, files: newFileData };
    });
    setValue('draft', card.draft, { shouldDirty: true });
  };

  const onReorderFile = async (
    fileId: string,
    reorderRequest: { step: number; direction: Direction },
  ) => {
    try {
      await patchCardFilesSorting(
        card['@id'].replace('/cards/', ''),
        fileId,
        reorderRequest,
      );
      showToast({
        type: 'success',
        title: 'Successfully updated file position',
      });
    } catch (e) {
      showToast({
        type: 'error',
        title: 'Can`t change file position',
      });
    }
    await getFullCardInfo();
  };
  const onApproveClick = () => {
    setIsApproveModalVisible(true);
  };
  const onApproveConfirmed = (form: PostCardRequestData) => {
    if (!card?.files?.length && form.mediaType === CardMediaTypes.audio_text) {
      showToast({
        type: 'warning',
        title: 'Please add audio files.',
        message:
          'Cards with prompt media - audio, should have at least 1 file. Please add files or change prompt media.',
      });
      return;
    }
    const { createdAt, active, ...patchCardFields } = form;
    const trimmedFields = {
      ...patchCardFields,
      title: patchCardFields.title.trim(),
      active: Boolean(active),
      responseLimitNum: Number(patchCardFields.responseLimitNum),
      promptTitle: patchCardFields.promptTitle.trim(),
      promptTitleShow: Boolean(patchCardFields.promptTitleShow),
      subtextRegular: patchCardFields.subtextRegular.trim(),
      promptFinishTab: patchCardFields.promptFinishTab.trim(),
      subtextStarter: patchCardFields.subtextStarter.trim(),
      ...(isMediaTypeCraft ? {
        initialUser: Boolean(patchCardFields.initialUser),
        initialUserShow: Boolean(patchCardFields.initialUserShow),
        initialUserPropositional: patchCardFields.initialUserPropositional || "",
        initialUserPromptTitle: patchCardFields.initialUserPromptTitle || null,
        initialUserSubText: patchCardFields.initialUserSubText || null,
        initialUserResponseLimitType: patchCardFields.initialUserResponseLimitType || null,
        initialUserResponseLimitNum: Number(patchCardFields.initialUserResponseLimitNum) || null,
      } : {
        initialUser: false,
        initialUserShow: null,
        initialUserPropositional: null,
        initialUserPromptTitle: null,
        initialUserSubText: null,
        initialUserResponseLimitType: null,
        initialUserResponseLimitNum: null,
      }),
    };
    setCard({ ...card, ...patchCardFields, files: card.files || [] });
    onApprove({ ...trimmedFields, files: card.files }, setError, [cardData.id]);
    closeModal();
  };
  const handleCreatingFlow = () => {
    if (cardData.isCreatingFlow) {
      setCurrentTab(cardInfoTabs.MEDIA);
      showToast({ type: 'info', title: 'Please add audio files.' });
    }
  };


  useEffect(() => {
    handleCreatingFlow();
    getFullCardInfo();
  }, []);

  return isCardLoading ? (
    <LoadingIndicator />
  ) : (
    <div className="flex flex-col h-full flex-1 items-stretch">
      <div className="flex justify-between items-start px-5 rounded-t relative">
        <button
          type="button"
          className="absolute left-3 top-5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
          onClick={() => {
            closeModal();
          }}
        >
          <HiArrowLeft className="w-5 h-5 " />
        </button>
        <ul className="text-sm text-gray-800 flex absolute top-5 right-3">
          <li className="flex items-center hover:bg-gray-100 p-2">
            <HiOutlineTrash
              className="w-5 h-5"
              onClick={() => setIsDeleteModalVisible(true)}
            />
          </li>
        </ul>
      </div>
      <div className="flex flex-col px-5 mt-14">
        <div className="flex text-sm w-full items-center">
          <p className="text-gray-500 w-36 text-md">TAB ID</p>
          <p className="text-gray-800 font-medium py-1 px-2">
            {card?.sequenceNumber}
          </p>
        </div>
        <div className="flex text-sm w-full items-center">
          <p className="text-gray-500 w-36 text-md">Creation time & Date</p>
          <p className="text-gray-800 font-medium py-1 px-2">
            {formatDateWithTime(card?.createdAt || '')}
          </p>
        </div>
      </div>
      <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 px-1.5">
        <ul className="flex flex-wrap -mb-p text-sm ml-2">
          {Object.keys(cardInfoTabs).map((tab) => (
            <li key={tab}>
              <button
                type="button"
                onClick={() => {
                  setCurrentTab(cardInfoTabs[tab]);
                }}
                className={classNames(
                  'inline-block mx-1.5 mt-2 pb-2 rounded-t-lg border-b hover:text-gray-600 hover:border-gray-300',
                  {
                    'text-gray-700 border-black':
                      currentTab === cardInfoTabs[tab],
                    'border-transparent': currentTab !== cardInfoTabs[tab],
                    hidden: !isMediaTypeAudioText && cardInfoTabs[tab] === cardInfoTabs.MEDIA
                  },
                )}
              >
                <p className="flex">
                  {cardInfoTabs[tab]}
                  {tab === 'CARDS' && (
                    <p className="ml-1 bg-gray-300 px-1 flex justify-center items-center text-xs rounded">
                      5
                    </p>
                  )}
                </p>
              </button>
            </li>
          ))}
        </ul>
      </div>
      {currentTab === cardInfoTabs.INFO && (
        <section className="flex w-full p-4 overflow-auto">
          <CardForm
            control={control}
            watch={watch}
            register={register}
            isUsed={card.used}
            formState={formState}
            updateValue={(fieldName, value, shouldDirty) => setValue(fieldName, value, {shouldDirty: shouldDirty || false})}
            clearErrors={clearErrors}
            isCreating={false}
          />
        </section>
      )}
      {currentTab === cardInfoTabs.MEDIA && (
        <CardFilesView
          files={card.files || []}
          onUpdateFile={onUpdateFile}
          onReorderFile={onReorderFile}
        />
      )}
      {currentTab === cardInfoTabs.CARD_VIEW && (
        <div className="pt-8 pl-4 mr-60">
          <CardPreview type={cardType} title={cardPromptTitle} />
        </div>
      )}
      {currentTab === cardInfoTabs.CARD_QR_CODE && (
        <CardQrCode id={card?.['@id']} />
      )}
      <Modal mode={ModalMode.center} isModalVisible={isDeleteModalVisible}>
        <div className="px-8 py-6">
          <p className="text-lg">Are you sure you want to delete this card?</p>
          <div className="bg-white h-16 flex justify-between items-center">
            <button
              type="button"
              className="bg-white text-blue-500 p-2 rounded"
              onClick={() => setIsDeleteModalVisible(false)}
            >
              Cancel
            </button>
            <button
              type="button"
              className="bg-blue-500 text-white p-2 rounded"
              onClick={() => {
                onDelete(card?.['@id']);
                setIsDeleteModalVisible(false);
              }}
            >
              Delete
            </button>
          </div>
        </div>
      </Modal>
      <Modal mode={ModalMode.center} isModalVisible={isApproveModalVisible}>
        <div className="px-8 py-6">
          <p className="text-lg">Are you sure you want to approve this card?</p>
          <div className="bg-white h-16 flex justify-between items-center">
            <button
              type="button"
              className="bg-white text-blue-500 p-2 rounded"
              onClick={() => setIsApproveModalVisible(false)}
            >
              Cancel
            </button>
            <button
              type="button"
              className="bg-blue-500 text-white p-2 rounded"
              onClick={handleSubmit(onApproveConfirmed)}
            >
              Approve
            </button>
          </div>
        </div>
      </Modal>
      <div className="bg-white h-16 flex justify-end items-center px-4">
        {currentTab === cardInfoTabs.INFO && (
          <button
            type="button"
            disabled={!(formState?.isDirty)}
            className="bg-blue-500 text-white p-2 rounded disabled:bg-gray-300"
            onClick={handleSubmit((data) => onSubmit(data, true))}
          >
            Save
          </button>
        )}
        {currentTab === cardInfoTabs.INFO ? (
          isDraft ? (
            <button
              type="button"
              className="ml-5 bg-blue-500 text-white p-2 rounded"
              onClick={onApproveClick}
            >
              Approve
            </button>
          ) : (
            <button
              type="button"
              className="ml-5 bg-blue-500 text-white p-2 rounded"
              onClick={handleSubmit(onDraftSubmit)}
            >
              Save as draft
            </button>
          )
        ) : null}
      </div>
    </div>
  );
};
