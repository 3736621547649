import { FC, useEffect } from 'react';
import {
  Control,
  FormState,
  useFieldArray,
  UseFormClearErrors,
  UseFormWatch,
  useWatch
} from 'react-hook-form';
import { showToast } from 'lib/toast';
import { CardFormShape } from '../CardForm';
import { CustomSelect } from '../../../common/CustomSelect';
import { TextInput } from '../../../common/TextInput';
import { TextareaInput } from '../../../common/TextareaInput';
import { PostCardRequestData } from 'lib/services/cardsServices';

type CardCraftSentenceProps = {
  formState: FormState<CardFormShape>;
  updateValue: (
    fieldName: keyof CardFormShape,
    value: string | boolean | number,
  ) => void;
  clearErrors: UseFormClearErrors<CardFormShape>
  watch: UseFormWatch<CardFormShape>;
  control: Control<CardFormShape>;
};

const promptPresets = {
  noun: "Select a noun",
  verb: "Select a verb",
  adjective: "Select an adjective",
  adverb: "Select an adverb",
  other: "Select a word",
  emoji: "Select emoji",
}

export const CardCraftSentence: FC<CardCraftSentenceProps> = ({
  formState,
  updateValue,
  clearErrors,
  watch,
  control,
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "craftExpressions"
  });

  const craftSentence = watch("craftSentence");
  const craftExpressions = useWatch({
    control,
    name: "craftExpressions",
    defaultValue: [],
  })

  useEffect(() => {
    if (formState?.errors?.craftExpressions?.message) {
      clearErrors("craftExpressions")
    }
  }, [craftExpressions]);

  const getArgumentsFromString = (str: string): string[] => {
    if (!str) return [];

    const matches = str.match(/\[(.*?)]/g)

    return matches ? matches.map(s => s.slice(1, -1)) : [];
  }

  const generateCraftExpressions = () => {
    if (!craftSentence) {
      return;
    }

    // Example usage: "lorem [3] frog test [45] from the [arg] my option [test 3] and the [54]!"
    const expressionIds = getArgumentsFromString(craftSentence); // ['3', '45', 'arg', 'test 3', '54']

    if (expressionIds.length === 0) {
      showToast({
        type: 'error',
        title: 'Craft sentence should contain at least one key.',
      });

      return;
    }

    const duplicateIds = expressionIds.filter((id) => expressionIds.indexOf(id) !== expressionIds.lastIndexOf(id));

    if (duplicateIds.length) {
      showToast({
        type: 'error',
        title: 'Craft sentence contains duplicated keys.',
      });

      return;
    }

    remove();

    const newCraftExpressions = expressionIds.map((id, index) => {
      const foundCraftExpression = craftExpressions?.find((expression) => expression.expressionId === id);

      if (foundCraftExpression) {
        // Only last craft expression can be with type "emoji"
        if (foundCraftExpression.type === 'emoji' && index !== expressionIds.length - 1) {
          return {
            ...foundCraftExpression,
            type: "noun",
            value: "",
            prompt: "Select a noun",
          }
        }

        return {
          ...foundCraftExpression
        };
      }

      return {
        expressionId: id,
        type: "noun",
        required: true,
        value: "",
        prompt: "Select a noun",
      }
    })

    append(newCraftExpressions);
  }

  return (
    <div className="">
      <div className="flex flex-col mb-2">
        <div className="w-full">
          <TextareaInput
            label="Sentence"
            name={'craftSentence'}
            rows={2}
            labelStyle="block text-sm text-gray-400 mb-2"
            inputStyle="w-full rounded mt-2 py-2 px-4 block border-gray-200 border-2"
            errorStyle="text-sm text-red-400 absolute -bottom-5"
            controlStyle="bg-blue-30 w-full mb-4 relative"
            control={control}
            placeholder="I really [A] how [B] you are and you would make a great [C] for a [D]!"
            rules={{required: "Field is required"}}
          />
        </div>
      </div>
      <div className="mb-6">
        <button type="button" className="bg-blue-500 text-white px-10 py-2 rounded" onClick={() => generateCraftExpressions()}>
          Define Fields
        </button>
      </div>

      {formState?.errors?.craftExpressions?.message && (
        <p className="text-sm text-red-400">
          {formState?.errors?.craftExpressions?.message}
        </p>
      )}

      <ul className="mb-24">
        {fields.map((field, index) => (
          <li key={field.id} className="flex mb-4">
            <p className="text-xl mt-8 w-24">[{field.expressionId}]</p>

            <div className="grid grid-cols-4 gap-4 flex-grow">
              <div>
                <CustomSelect
                  label="Type"
                  name={`craftExpressions.${index}.type`}
                  labelStyle="block text-sm text-gray-400 mb-2"
                  controlStyle="bg-blue-30 w-full relative"
                  control={control}
                  menuPlacement={fields.length - index > 1 ? "auto" : "top"}
                  options={[
                    { value: 'noun', label: 'Noun' },
                    { value: 'verb', label: 'Verb' },
                    { value: 'adjective', label: 'Adjective' },
                    { value: 'adverb', label: 'Adverb' },
                    { value: 'other', label: 'Other' },
                    { value: 'emoji', label: 'Emoji', isDisabled: fields.length !== index + 1 }
                  ]}
                  onCustomChange={(value) => {
                    if (typeof promptPresets[value as keyof typeof promptPresets] === 'string') {
                      updateValue(
                        `craftExpressions.${index}.prompt` as keyof PostCardRequestData,
                        promptPresets[value as keyof typeof promptPresets]
                      )
                    }
                  }}
                />
              </div>
              <div>
                <TextInput
                  label="Prompt"
                  name={`craftExpressions.${index}.prompt`}
                  labelStyle="block text-sm text-gray-400 mb-2"
                  inputStyle="w-full rounded mt-2 py-2 px-4 block border-gray-200 border-2"
                  errorStyle="text-sm text-red-400 absolute -bottom-5"
                  controlStyle="bg-blue-30 w-full relative"
                  control={control}
                  rules={{required: "Field is required"}}
                />
              </div>
              <div className="col-span-2">
                <TextareaInput
                  name={`craftExpressions.${index}.value`}
                  label="Value"
                  rows={1}
                  labelStyle="block text-sm text-gray-400 mb-2"
                  inputStyle="w-full rounded mt-2 py-2 px-4 block border-gray-200 border-2"
                  errorStyle="text-sm text-red-400 absolute -bottom-5"
                  controlStyle="bg-blue-30 w-full relative"
                  control={control}
                  rules={{required: "Field is required"}}
                  placeholder="admire, love, respect..."
                />
              </div>
              <div className="col-span-3">
                {formState?.errors?.craftExpressions?.[index]?.message && (
                  <p className="text-sm text-red-400">
                    {formState?.errors?.craftExpressions?.[index]?.message}
                  </p>
                )}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
