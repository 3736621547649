import { FC } from 'react';
import { Control, Controller, RegisterOptions } from 'react-hook-form';

type TextareaInputProps = {
  control: Control<any, any>;
  name: string;
  label?: string;
  placeholder?: string;
  inputStyle: string;
  labelStyle?: string;
  controlStyle: string;
  errorStyle: string;
  isDisabled?: boolean;
  rows?: number;
  rules?: RegisterOptions<any, string>
};

export const TextareaInput: FC<TextareaInputProps> = ({
  control,
  name,
  label,
  placeholder,
  inputStyle,
  labelStyle,
  controlStyle,
  errorStyle,
  rules,
  rows,
  isDisabled,
}) => {
  return (
    <>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { value, onChange }, fieldState }) => (
          <div className={controlStyle}>
            <div style={{ position: 'relative' }}>
              {label && (
                <label htmlFor={name} className={labelStyle}>
                  {label}
                </label>
              )}
              <textarea
                value={value}
                placeholder={placeholder}
                onChange={onChange}
                rows={rows}
                className={`min-h-[44px] ${inputStyle} ${isDisabled ? 'bg-gray-100 text-gray-400' : ''}`}
                disabled={isDisabled}
              />
            </div>
            {fieldState.error && (
              <p className={errorStyle}>{fieldState.error.message}</p>
            )}
          </div>
        )}
      />
    </>
  );
};
